import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import font from "../../fonts/fonts.module.scss"

const ServiceTextDoctor = ({ doctor, data }) => {
    console.log(data)

    return (
        <StyledServiceTextDoctor>
            <div className="container">
                <div className={font.rSB + " wrapper"}>
                    <h2>{data.consult}</h2>
                    <Link>
                        <button className="btn">
                            <Link className={font.rSB} to="#marcar">Marcar consulta</Link>
                        </button>
                    </Link>
                </div>
                <div style={{ display: "flex" }}>
                    {doctor.map((doctorDetails, k) => (
                        <div className="wrapper-person" key={k}>
                            <img src={doctorDetails.img} className="image" />
                            <div className="person-info">
                                <div className={font.rSB + " title"}>
                                    <p >{doctorDetails.name}</p>
                                    <p className={font.rR + " tisubtitle"} dangerouslySetInnerHTML={{ __html: doctorDetails.cargo }} />

                                </div>
                                <div className={font.rR + " subtitle"}>
                                    <p>{doctorDetails.omd}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </StyledServiceTextDoctor >
    )
}

export default ServiceTextDoctor

const StyledServiceTextDoctor = styled.div`
background-color: #F5F5F5;
position: relative;
top: -0.5rem;

.container {
    display: flex;
    align-items: center;
    padding: 6% 12%;
    justify-content: space-between;
    max-width: 1920px;
    margin: 0 auto;

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 33rem;
        gap: 2rem;


        @media only screen and (max-width: 1440px) {
            max-width: 25rem;
        }


        h2 {
            font-size: 2rem;

            @media only screen and (max-width: 1440px) {
                font-size: 1.2rem;
            }
        }

        .btn {
            border: 2px solid black;
            padding: 3% 3%;
            font-size: 1rem;
            
            :hover {
                background-color: #000;
                color: #fff;
            }
        }
    }
    
    .wrapper-person {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        white-space: nowrap;
        justify-content: flex-end;
        width: 309px;

        @media only screen and (max-width: 1440px) {
            width: 242px;
        }

        .person-info {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 2rem;

            .title {
                font-size: 1.2rem;

                .tisubtitle {

                }
            }

            .subtitle {
                font-size: 1rem;
            }
        }

        .image {
            width: 30%;
        }
    }
}
`