import * as React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import font from "../../fonts/fonts.module.scss"

const BannerTextInfo = ({ data }) => {

    return (
        <BannerTextInfoStyled>
            <img src={data.banner} className="image-banner" />
            <div className='container'>
                <div className='content'>
                    <h1 className={font.rB + " title"}>{data.title}</h1>
                    <p className={font.rM + " subtitle"} dangerouslySetInnerHTML={{ __html: data.text }} />

                    <Link to="#marcar">
                        <button className="btn">
                            <p className={font.rSB}>Marcar Consulta</p>
                        </button>
                    </Link>
                </div>
                <img src={data.intro} className="image" />
            </div>
        </BannerTextInfoStyled>
    )
}


export default BannerTextInfo

const BannerTextInfoStyled = styled.div`

.image-banner{
    width: 100%;
  }

    .container{
        background-color: #DED6CD;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content: space-between;
        position: relative;
        margin-top: -4px;

        .content {
            padding: 0 5%;

            .title {
                font-size: 2rem;
                margin-bottom: 1rem;
            }
            .subtitle{
                font-size: 1rem;
                line-height: 2.5rem;
                margin-bottom: 1rem;
            }

            ul {
                list-style: none;
            }

            .sub-list-subtitle {
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }

        }

        .image{
            width: 50%;
        }     

        .btn {
            border: 2px solid black;
            padding: 3% 3%;
            font-size: 1rem;

            :hover {
                background-color: #000;
                color: #fff;
            }
        }
    }
`