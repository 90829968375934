import React, { useRef } from 'react'

import styled from "styled-components"
import Slider from "react-slick"
import font from "../../fonts/fonts.module.scss"


const BannerSlideInfoMobile = ({ info }) => {

    const customeSlider = useRef();

    // setting slider configurations
    var settings = {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        dots: false,
    }

    const next = () => {
        customeSlider.current.slickNext();
    };


    return (
        <StyledBannerSlideInfoMobile>
            <Slider {...settings} ref={customeSlider}>
                {info.slide.map((data, i) => (
                    <div className="slide" key={"clinicaSlide" + i}>
                        <div className="clinica" >
                            <img src={data.img} className='image' />
                            <div className="space">
                                <div className="textImg">
                                    <h1 className={font.rB}>{data.title}</h1>
                                    <br></br>
                                    <p className={font.rR} dangerouslySetInnerHTML={{ __html: data.subtitle }} />
                                    <br></br>
                                    <button onClick={next} className={font.rSB}>{data.step}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </StyledBannerSlideInfoMobile>

    )
}

export default BannerSlideInfoMobile

const StyledBannerSlideInfoMobile = styled.div`
  position: relative;

  .clinica {
    .image {
        width: 100%;
    }
    .space {
        padding: 7%;

        .textImg {
            h1 {
                font-size: 1.5rem;
            }

            button {
                font-size: 1rem;
                font-style: italic;
                float: right; 
            }

            ul {
                list-style: none;
            }

            li {
                margin-bottom: 2%;
            }

            p {
                margin-bottom: 2%;
                line-height: 1.8rem;
            }
        }
    }
  }

  .slick-slider{
    overflow:hidden;
    position:relative;

    .clinica {
        .container {
            .black{
                color: blue;
            }
        }
      }

    .slick-arrow{
        position: absolute;
        top: 50%;
        transform:translateY(-50%);
        cursor:pointer;
        z-index:1;

        .cls-2{
            fill:#000;

        }
    }

    .slick-prev{
        left:1.5rem;
    }

    .slick-next{
        right:1.5rem;
    }

    .slick-disabled{
        opacity:0;
        transition: opacity 0.3s;
    }

    .slick-track{
        display:flex;
    }
}   
`