import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import font from "../../fonts/fonts.module.scss"

const ServiceTextDoctorMobile = ({ doctor, data }) => {

    return (
        <StyledServiceTextDoctorMobile>
            <div className="container">
                <div className={font.rSB + " wrapper"}>
                    <h2>{data.consult}</h2>
                    <Link to="#marcar">
                        <button className="btn">
                            <p className={font.rSB}>Marcar Consulta</p>
                        </button>
                    </Link>
                </div>
                {doctor.map((doctorDetails, k) => (
                    <div className="wrapper-person" key={k}>
                        <img src={doctorDetails.img} className="image" />
                        <div className="person-info">
                            <div className={font.rSB + " title"}>
                                <p >{doctorDetails.name}</p>
                                <p className={font.rR + " tisubtitle"} dangerouslySetInnerHTML={{ __html: doctorDetails.cargo }} />
                            </div>
                            <div className={font.rR + " subtitle"}>
                                <p>{doctorDetails.omd}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </StyledServiceTextDoctorMobile >
    )
}

export default ServiceTextDoctorMobile

const StyledServiceTextDoctorMobile = styled.div`
background-color: #F5F5F5;

.container {
    display: flex;
    align-items: center;
    padding: clamp(60px,10vw,150px) 12%;
    justify-content: space-between;
    flex-direction: column;

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 35rem;

        @media only screen and (max-width: 1400px) {
            max-width: 32rem;
        }


        h2 {
            margin-bottom: 3rem;
            font-size: 1.5rem;
            
        }

        .btn {
            border: 2px solid black;
            padding: 3% 7%;
            font-size: 1.2rem;

            :hover {
                background-color: #000;
                color: #fff;
            }
        }
    }
    
    .wrapper-person {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding-top: 75px;
        white-space: nowrap;
        flex-direction: column;
        text-align: center;

        .person-info {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .title {
                font-size: 1.2rem;

                .tisubtitle {

                }
            }

            .subtitle {
                font-size: 1rem;
            }
        }

        .image {
            width: 70%;
        }
    }
}
`