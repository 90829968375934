import React, { useRef } from 'react'
import styled from "styled-components"
import Slider from "react-slick"
import font from "../../fonts/fonts.module.scss"

const BannerSlideInfo = ({ info }) => {

    //creating the ref
    const customeSlider = useRef();

    // setting slider configurations
    var settings = {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        dots: false,
    }

    const next = () => {
        customeSlider.current.slickNext();
    };


    return (
        <StyledBannerSlideInfo>
            <Slider {...settings} ref={customeSlider}>
                {info.slide.map((item, i) => (
                    <div className="slide" key={"clinicaSlide" + i}>
                        <div className="clinica" >
                            <img src={item.img} alt={item.title} className='image' />
                            <div className="space">
                                <div className="textImg">
                                    <h1 className={font.rB}>{item.title}</h1>
                                    <br></br>
                                    <div className={font.rR} style={{ lineHeight: '1.8rem' }} dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                                    <br></br>
                                    <button onClick={next} className={font.rSB}>{item.step}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </StyledBannerSlideInfo>

    )
}

export default BannerSlideInfo

const StyledBannerSlideInfo = styled.div`
  position: relative;
  top: -4px;

  .clinica {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    .image {
        width: 50%;
        float: right;
    }

    .space {
        

        .textImg {
            position: relative;
            top: 50%;
            left: 6%;
            margin-right: -50%;
            max-width: 425px;
            font-size: 18px;
            padding: 5% 0;
            margin-left: 11rem;

            @media only screen and (max-width: 1440px) {
                margin-left: 8rem;
            } 

            h1 {
                font-size: 2rem;
            }

            button {
                font-size: 1rem;
                font-style: italic; 
                float: right;
            }

            ul {
                list-style: none;
            }

            p {
                margin-bottom: 2%;
                line-height: 1.8rem;
            }
        }
    }
  }

  .slick-slider{
    overflow:hidden;
    position:relative;

    .clinica {
        .container {
            .black{
                color: blue;
            }
        }
      }

    .slick-arrow{
        position: absolute;
        top: 50%;
        transform:translateY(-50%);
        cursor:pointer;
        z-index:1;

        .cls-2{
            fill:#000;
        }
    }

    .slick-prev:before, .slick-next:before {
        color: #000;
    }

    .slick-prev{
        left:1.5rem;
    }

    .slick-next{
        right:1.5rem;
    }

    .slick-disabled{
        opacity:0;
        transition: opacity 0.3s;
    }

    .slick-track{
        display:flex;
    }
}   
`